import "parsleyjs";

export class Forms {
    forms = null;
    parsleyParams = {
        excluded: Parsley.options.excluded + ', input:hidden, select:hidden, textarea:hidden',
        errorClass: 'is-invalid',
        successClass: 'is-valid',
        errorsWrapper: '<div class="invalid-feedback leading-tight"></div>',
        errorTemplate: '<span></span>',
        errorsContainer: function (Field) {
            if (Field.element.type === 'checkbox' || Field.element.type === 'radio') {
                return Field.$element.closest('.input').find('> :visible:last');
            }
        },
        classHandler: function (Field) {
            if (Field.element.type === 'checkbox' || Field.element.type === 'radio') {
                return Field.$element.closest('.input').find('> :visible input');
            }
        }
    }

    constructor($selector = $('form')) {
        if ($selector.length > 0) {
            this.forms = $selector;
        }
    }

    validate() {
        if (this.forms) {
            this.forms.parsley(this.parsleyParams);
        }
    }

    steps() {
        var $sections = $('.form-section');

        function navigateTo(index) {
            // Mark the current section with the class 'current'
            $sections
                .removeClass('d-block')
                .addClass('d-none')
                .eq(index)
                .addClass('d-block');
            // Show only the navigation buttons that make sense for the current section:
            $('.form-navigation .previous').toggle(index > 0);
            var atTheEnd = index >= $sections.length - 1;
            $('.form-navigation .next').toggle(!atTheEnd);
            $('.form-navigation [type=submit]').toggle(atTheEnd);
        }

        function curIndex() {
            // Return the current index by looking at which section has the class 'current'
            return $sections.index($sections.filter('.d-block'));
        }

        // Previous button is easy, just go back
        $('.form-navigation .previous').click(function () {
            navigateTo(curIndex() - 1);
        });

        // Next button goes forward iff current block validates
        $('.form-navigation .next').click(function () {
            let form = $(this).closest('form');
            form.parsley(this.parsleyParams).whenValidate({
                group: 'block-' + curIndex()
            }).done(function () {
                navigateTo(curIndex() + 1);
            });
        });

        // Prepare sections by setting the `data-parsley-group` attribute to 'block-0', 'block-1', etc.
        $sections.each(function (index, section) {
            $(section).find('.input').attr('data-parsley-group', 'block-' + index);
        });
        navigateTo(0); // Start at the beginning
    }


}